export const getPaddingValue = (str) => {
  if (str === 'xl') {
    return { xs: 4, md: 4 };
  }
  if (str === 'lg') {
    return { xs: 2, md: 3 };
  }
  if (str === 'md') {
    return { xs: 2, md: 2 };
  }
  if (str === 'sm') {
    return { xs: 1.5, md: 1.5 };
  }
  if (str === 'xs') {
    return { xs: 1, md: 1 };
  }
  return { xs: 3, md: 3 };
};

export const getSpacingValue = (str) => {
  if (str === 'xl') {
    return { xs: 3, md: 4 };
  }
  if (str === 'lg') {
    return { xs: 2, md: 3 };
  }
  if (str === 'md') {
    return { xs: 1.5, md: 2 };
  }
  if (str === 'sm') {
    return { xs: 1.25, md: 1.5 };
  }
  if (str === 'xs') {
    return { xs: 0.75, md: 1 };
  }
  return { xs: 2, md: 3 };
};

export const getMaxWidth = (currentWidth) => {
  if (currentWidth === 'xs') {
    return 616; // 600 + 2*8
  }
  if (currentWidth === 'sm') {
    return 816; // 800 + 2*8
  }
  if (currentWidth === 'md') {
    return 976; // 960 + 2*8
  }
  if (currentWidth === 'lg') {
    return 1232; // 1200 + 4*8
  }
  return 1536;
};

export const getMaxHeadingWidth = (currentWidth) => {
  if (currentWidth === 'xs') {
    return 616; // 600 + 2*8
  }
  if (currentWidth === 'sm') {
    return 616; // 800 + 2*8
  }
  if (currentWidth === 'md') {
    return 816; // 960 + 2*8
  }
  if (currentWidth === 'lg') {
    return 976; // 1200 + 4*8
  }
  return 1232;
};

export const getContainerPx = (currentWidth) => {
  if (currentWidth === 'xs') {
    return { xs: 3, md: 4 };
  }
  if (currentWidth === 'sm') {
    return { xs: 3, md: 4 };
  }
  if (currentWidth === 'md') {
    return { xs: 3, md: 4 };
  }
  if (currentWidth === 'lg') {
    return { xs: 3, md: 5 };
  }
  return { xs: 3, md: 3 };
};
