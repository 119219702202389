'use client';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useResponsive } from '../../hooks/use-responsive';
import { Image } from '../../components/landing';
import PrismicTypography from '../../components/prismic/prismic-typography';
import PrismicContainer from '../../components/prismic/prismic-container';
import { getSliceSettings } from '../../prismicio';

// Note: Carousel can't have negative margins from sx, or the animation won't start again right from the beginning
const REFERENCE_LOGOS = [
  {
    name: 'Wannado',
    src: '/assets/images/reference-logos/wannado.png',
    padding_bottom: 1,
    opacity: 0.75,
    grayscale: true,
    contrast: 1.5,
    sx: { opacity: 0.75, pb: 0.25 },
  },
  {
    name: 'Ruokaboksi',
    src: '/assets/images/reference-logos/ruokaboksi.png',
    grayscale: true,
    contrast: 1.5,
  },
  {
    name: 'Schoffa',
    src: '/assets/images/reference-logos/schoffa.webp',
    opacity: 0.7,
    grayscale: true,
    contrast: 1.5,
    sx: { opacity: 0.7 },
  },
  {
    name: 'Stolt',
    src: '/assets/images/reference-logos/stolt.webp',
    padding_bottom: 4,
    opacity: 0.7,
    width_modifier: -30,
    grayscale: true,
    contrast: 1.5,
    sx: { opacity: 0.7, pb: 0.5 },
  },
  {
    name: 'Tagomo', src: '/assets/images/reference-logos/tagomo.png',
    padding_top: 8,
    opacity: 0.75,
    grayscale: true,
    contrast: 1.5,
    sx: { opacity: 0.7, pt: 1 },
  },
  {
    name: 'ImageLab',
    src: '/assets/images/reference-logos/imagelab.png',
    width_modifier: 30,
    grayscale: true,
    contrast: 1.5,
  },
  {
    name: '24Center',
    src: '/assets/images/reference-logos/24center.png',
    opacity: 0.6,
    width_modifier: -50,
    grayscale: true,
    contrast: 1.5,
    sx: { opacity: 0.6 },
  },
  {
    name: 'Lilja the Label',
    src: '/assets/images/reference-logos/liljathelabel.png',
    padding_top: 6,
    opacity: 0.7,
    grayscale: true,
    contrast: 1.5,
    sx: { opacity: 0.7, pt: 0.75, m: { xs: 0, md: -1.75 }, ml: { xs: 0, md: -2 } },
  },
];

/**
 * @typedef {import('@prismicio/client').Content.ReferenceLogosSlice} ReferenceLogosSlice
 * @typedef {import('@prismicio/react').SliceComponentProps<ReferenceLogosSlice>} ReferenceLogosProps
 * @param {ReferenceLogosProps}
 */
const ReferenceLogos = ({ slice, context, isSubSlice = false }) => {
  const isMobile = useResponsive('down', 'sm');
  const defaultSettings = { ...context?.defaultSettings, slicePadding: 'sm' };

  const { containerWidth, slicePadding, sliceSpacing, sliceVariant } = getSliceSettings(
    slice,
    defaultSettings,
  );

  if (!slice) {
    return null;
  }

  const logos = slice.primary.logos?.length > 0 ? slice.primary.logos : REFERENCE_LOGOS;

  return (
    <PrismicContainer
      slice={slice}
      variant={sliceVariant}
      width={containerWidth}
      slicePadding={slicePadding}
      sliceSpacing={sliceSpacing}
      disablePaddingBottom={!!slice.primary.video_url || slice.primary.is_bottom_padding_disabled}
      sx={{ overflow: 'hidden', bgcolor: sliceVariant !== 'dark' ? 'background.light' : null }}
    >
      {isMobile
        ? <MobileReferenceLogos slice={slice} logos={logos} sliceVariant={sliceVariant} speed={slice.primary.scroll_time_per_logo || 4} />
        : <DesktopReferenceLogos slice={slice} logos={logos} sliceVariant={sliceVariant} />
      }
    </PrismicContainer>
  );
};

export default ReferenceLogos;
ReferenceLogos.propTypes = {
  slice: PropTypes.any,
  context: PropTypes.object,
  isSubSlice: PropTypes.bool,
};

// ----------------------------------------------------------------------

const DesktopReferenceLogos = ({ slice, logos, sliceVariant }) => (
  <Stack>
    {slice?.primary.title && <Stack>
      <PrismicTypography variant="h5" align="center" sx={{ mt: 1, mb: 2.5, zoom: 0.95 }}
                         colorVariant={sliceVariant}>
        {slice.primary.title}
      </PrismicTypography>
    </Stack>}
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ flexWrap: 'wrap' }}
    >
      {logos.map((logo, index) => (
        <Stack
          sx={{ mx: 3, my: 1, height: 50 }}
          spacing={1}
          justifyContent="center"
          alignItems="center"
          key={index}
        >
          <Image
            src={logo.image?.url || logo.src}
            sx={{
              width: {
                xs: 90 + (logo.width_modifier ?? 0),
                lg: 100 + (logo.width_modifier ?? 0),
                xl: 100 + (logo.width_modifier ?? 0),
              },
              filter: `grayscale(${logo.grayscale ? 1 : 0})  contrast(${logo.contrast || 1})`,
              pt: logo.padding_top ? logo.padding_top / 8 : 0,
              pb: logo.padding_bottom ? logo.padding_bottom / 8 : 0,
              opacity: logo.opacity ?? 1,
            }}
          />
        </Stack>
      ))}
    </Stack>
  </Stack>);

DesktopReferenceLogos.propTypes = {
  slice: PropTypes.any,
  logos: PropTypes.array,
  sliceVariant: PropTypes.string,
};
// ----------------------------------------------------------------------

const MobileReferenceLogos = ({ slice, logos, speed = 4, sliceVariant }) => {
  const imagePx = 5;
  const logosWidth = logos.reduce(
    (acc, logo) => acc + (90 + imagePx * 2 * 8 + (logo.width_modifier || 0)),
    0,
  );
  const renderArray = [...Array(2)];

  return (
    <Stack sx={{ mb: -3 }}>
      {slice?.primary.title && <Stack>
        <PrismicTypography
          variant="h5"
          align="center"
          colorVariant={sliceVariant}
          sx={{ mb: 1, px: 3, lineHeight: 1.2, zoom: 0.95 }}
        >
          {slice.primary.title}
        </PrismicTypography>
      </Stack>}
      <Box
        sx={{
          width: logosWidth,
          margin: 'auto',
          overflow: 'visible',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: 0,
            animation: `scroll-left ${speed * logos.length}s infinite linear`,
            alignItems: 'center',
          }}
        >
          {renderArray.map((value, index) =>
            logos.map((logo, logoIndex) => (
              <Box sx={{ px: imagePx }} key={`${index}-${logoIndex}`}>
                <Image
                  src={logo.image?.url || logo.src}
                  disableLazyLoad
                  sx={{
                    width: {
                      xs: 90 + (logo.width_modifier ?? 0),
                      lg: 100 + (logo.width_modifier ?? 0),
                      xl: 100 + (logo.width_modifier ?? 0),
                    },
                    filter: `grayscale(${logo.grayscale ? 1 : 0})  contrast(${logo.contrast || 1})`,
                    pt: logo.padding_top ? logo.padding_top / 8 : 0,
                    pb: logo.padding_bottom ? logo.padding_bottom / 8 : 0,
                    opacity: logo.opacity ?? 1,
                  }}
                />
              </Box>),
            ),
          )}
        </Box>
      </Box>
    </Stack>);
};

MobileReferenceLogos.propTypes = {
  slice: PropTypes.any,
  logos: PropTypes.array,
  speed: PropTypes.number,
  sliceVariant: PropTypes.string,
};

