import { useState, useRef, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import PropTypes from 'prop-types';

const PinInput = ({ length = 6, onComplete }) => {
  const [pin, setPin] = useState(Array(length).fill(''));
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const setInputRef = (el, index) => {
    if (el) {
      inputRefs.current[index] = el;
    }
  };

  const handleChange = (index, value) => {
    if (!/^\d?$/.test(value)) return;

    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newPin.every(num => num !== '')) {
      onComplete?.(newPin.join(''));
    }
  };

  const handleKeyDown = (index, event) => {
      if (event.key === 'Backspace') {
        const newPin = [...pin];

        if (!pin[index] && index > 0) {
          inputRefs.current[index - 1]?.focus();
        } else {
          for (let i = index; i < length; i += 1) {
            newPin[i] = '';
          }
          setPin(newPin);
        }
      }
      if (event.key === 'ArrowLeft') {
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        }
      }
      if (event.key === 'ArrowRight') {
        if (index < length - 1) {
          inputRefs.current[index + 1]?.focus();
        }
      }
    };

  return (
    <Box display="flex" gap={{ xs: 1, md: 2 }} justifyContent="center">
      {pin.map((num, index) => (
        <TextField
          key={index}
          inputRef={(el) => setInputRef(el, index)}
          type="text"
          variant="outlined"
          size="small"
          inputProps={{ maxLength: 1, style: { textAlign: 'center', fontSize: '1.5rem' } }}
          value={num}
          onChange={e => handleChange(index, e.target.value)}
          onKeyDown={e => handleKeyDown(index, e)}
          sx={{
            width: { xs: 40, md: 50 },
            '& .MuiInputBase-input': {
              px: { xs: 1 },
            },
            '& .MuiInputBase-root': {
              bgcolor: 'background.paper',
            },
          }}
        />
      ))}
    </Box>
  );
};

export default PinInput;

PinInput.propTypes = {
  length: PropTypes.number,
  onComplete: PropTypes.func,
};
