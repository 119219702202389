'use client';

import Stack from '@mui/material/Stack';
import { Box, Container, InputAdornment, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PrismicTypography from 'src/components/prismic/prismic-typography';
import { axiosPost } from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import Iconify from 'src/components/iconify';
import PinInput from 'src/components/input/pin-input';
import { useAuthContext } from '../../auth/hooks';
import { fireSignupValidated } from '../../utils/tracking';
import PrismicOnboardingNavigation from '../../components/prismic/onboarding/prismic-onboarding-navigation';
import Link from '../../components/link/link';

const SKIP_PHONE_VERIFICATION_IF_DONE = true;
const EXAMPLE_COUNTRY_CODE = '+44';

let isPhoneNumberOnNextCalled = false;

/**
 * @typedef {import('@prismicio/client').Content.OnboardingPhoneNumberSlice} OnboardingPhoneNumberSlice
 * @typedef {import('@prismicio/react').SliceComponentProps<OnboardingPhoneNumberSlice>} OnboardingPhoneNumberProps
 * @type {import('react').FC<OnboardingPhoneNumberProps>}
 */
const OnboardingPhoneNumber = ({ slice, context }) => {
  const onNext = context?.onNext;
  const onPrevious = context?.onPrevious;

  const { user, refreshUser } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [hasSent, setHasSent] = useState(false);

  useEffect(() => {
    if (SKIP_PHONE_VERIFICATION_IF_DONE && user.phoneNumberVerifiedAt && !isPhoneNumberOnNextCalled) {
      isPhoneNumberOnNextCalled = true;
      onNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  const checkIsValidPhoneNumber = (value) => {
    if (!value.startsWith('+')) {
      setPhoneNumberError(`Phone number must start with a country code (e.g., ${EXAMPLE_COUNTRY_CODE})`);
      return false;
    }

    const parsedNumber = parsePhoneNumberFromString(value);

    if (!parsedNumber || !parsedNumber.isValid()) {
      setPhoneNumberError('Invalid phone number format.');
      return false;
    }

    setPhoneNumberError(null);
    return true;
  };

  const onSubmitPhoneNumber = async () => {
    const isValidPhoneNumber = checkIsValidPhoneNumber(phoneNumber || '');

    if (!isValidPhoneNumber) {
      return;
    }

    const url = `/auth/verify/phone/send`;
    setIsSending(true);

    try {
      const { success, message } = await axiosPost(url, {
        phoneNumber,
      });

      if (success) {
        setHasSent(true);
      }
      setIsSending(false);

      if (!success) {
        onNext(); // If there is error, let through
      }
    } catch (error) {
      console.error(error);
      // enqueueSnackbar(typeof error === 'string' ? error : error.message, {
      //   variant: 'error',
      //   autoHideDuration: 10000,
      // });
      setIsSending(false);

      onNext(); // If there is error, let through
    }
  };

  const onVerifyPinCode = async (code) => {
    const url = `/auth/verify/phone/check`;
    setIsSending(true);

    try {
      const { success, message } = await axiosPost(url, {
        code,
        phoneNumber,
      });

      if (success) {
        fireSignupValidated();

        if (SKIP_PHONE_VERIFICATION_IF_DONE) {
          isPhoneNumberOnNextCalled = false;
          refreshUser(); // This has useEffect which calls onNext
        }
        if (!SKIP_PHONE_VERIFICATION_IF_DONE) {
          onNext();
        }
      }
      if (!success) {
        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 10000,
        });
      }
      setIsSending(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(typeof error === 'string' ? error : error.message, {
        variant: 'error',
        autoHideDuration: 10000,
      });
      setIsSending(false);
    }
  };


  const renderPhoneNumberInput = <Stack spacing={0.75}>
    <Stack>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: '1.1rem', lineHeight: 1.5, my: 0.3 }}
      >
        {slice.primary.phone_number_input_title || 'Insert your phone number including country code'}
      </Typography>
    </Stack>
    <TextField
      size="large"
      defaultValue={phoneNumber}
      onChange={(event) => {
        if (isSending) {
          return;
        }
        setPhoneNumber(event.target.value);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.target.blur();
          onSubmitPhoneNumber();
        }
      }}
      focused
      name="phone"
      type="tel"
      fullWidth
      placeholder={`e.g. ${EXAMPLE_COUNTRY_CODE} 20 7123 4567`}
      InputProps={{
        startAdornment: (
          <Stack direction="row" alignItems="center">
            <InputAdornment position="start" sx={{ mt: 0.125, ml: 0.5, mr: 1 }}>
              {isSending
                ? <Iconify icon="svg-spinners:8-dots-rotate" width={24} color="accent.main" sx={{ mr: 0.5 }} />
                : <Iconify
                  icon="solar:phone-linear"
                  sx={{ width: 28, height: 28, color: 'accent.main' }}
                />}
            </InputAdornment>
          </Stack>
        ),
        endAdornment:
          false && <Button
            size="default"
            variant="contained"
            sx={{
              pl: 3.5,
              pr: 2.75,
              mr: -0.75,
              ml: 0.75,
              fontSize: '1.05rem',
              minWidth: 30,
            }}
            color="accent"
            disabled={isSending}
            onClick={onSubmitPhoneNumber}
            endIcon={<Iconify icon="solar:double-alt-arrow-right-bold" width={24} />}
          >
            {slice.primary.phone_number_send_label || 'Send'}
          </Button>,
      }}
      sx={{
        pointerEvents: isSending ? 'none' : 'auto',
        minWidth: 150,
        '& .MuiInputBase-input': {
          typography: 'body1',
          pl: 1.25,
          borderTopLeftRadius: '0!important',
          borderBottomLeftRadius: '0!important',
        },
        '& .MuiInputBase-root': {
          bgcolor: 'background.paper',
        },
      }}
    />
    {phoneNumberError && <Typography
      variant="subtitle2"
      sx={{
        mt: 0.125,
        color: 'error.main',
        textAlign: { xs: 'left', md: 'left' },
      }}
    >
      {phoneNumberError}
    </Typography>}

    {!phoneNumberError && slice.primary.phone_number_input_description && <Typography
      variant="body2"
      sx={{
        mt: 0.125,
        textAlign: { xs: 'left', md: 'left' },
      }}
    >
      {slice.primary.phone_number_input_description}{' '}
      {slice.primary.is_terms_visible && <Box component="span">
        View{' '}
        <Link href="/policies/terms/" target="_blank" sx={{ color: 'text.primary', textDecoration: 'underline' }}>Terms</Link>{' '}and{' '}
        <Link href="/policies/privacy-policy/" target="_blank" sx={{ color: 'text.primary', textDecoration: 'underline' }}>Privacy Policy</Link>.
      </Box>}
    </Typography>}
  </Stack>;

  const renderPinCodeInput = <Stack spacing={1.5}>
    <Stack>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: '1.1rem' }} gutterBottom>{slice.primary.pin_input_title || 'Please enter the 6-digit PIN code'}</Typography>
      <Stack sx={{ mr: 'auto' }}>
        <PinInput length={6} onComplete={(code) => {
          onVerifyPinCode(code);
        }} />
      </Stack>
    </Stack>
    <Stack direction="row" alignItems="center" flexWrap="wrap">
      <Typography variant="body2" sx={{ mr: 1, lineHeight: 1.5 }}>The PIN code has been sent to {phoneNumber}. </Typography>
      <Button variant="text" sx={{ verticalAlign: 'middle', fontWeight: 500, p: 0 }} onClick={() => setHasSent(false)}>
        Try again.
      </Button>
    </Stack>
  </Stack>;

  return (
    <Container maxWidth="md">
      <section data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
        <Stack spacing={3.5}>
          <Stack spacing={3}>
            <Stack spacing={1.5}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: '2.1rem!important', md: '2.5rem!important' },
                  lineHeight: 1.2,
                }}
              >
                {slice.primary.title}
              </Typography>
              <PrismicTypography>{slice.primary.description}</PrismicTypography>
            </Stack>
          </Stack>

          {!hasSent && renderPhoneNumberInput}

          {hasSent && renderPinCodeInput}

          {false && <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            sx={{ mt: 1.5, mb: 0.75 }}
          >
            {onPrevious && (<>
                {hasSent && <Box sx={{ flexGrow: 1 }} />}
                <Button
                  size="small"
                  sx={{ ml: -3.5, px: 3.5, py: 3.25, fontSize: '1.05rem' }}
                  onClick={onPrevious}
                >
                  Previous
                </Button>
              </>
            )}
            {hasSent && <Button
              size="large"
              variant="contained"
              sx={{ px: 3.5, py: 3.25, fontSize: '1.05rem' }}
              color="accent"
              onClick={onNext}
            >
              {slice.primary.next_button_title || 'Continue'}
            </Button>}
          </Stack>}
          <PrismicOnboardingNavigation onNext={hasSent ? onNext : onSubmitPhoneNumber}
                                       nextLabel={hasSent ? (slice.primary.next_button_title || 'Continue') : (slice.primary.phone_number_send_label || 'Send')}
                                       onPrevious={onPrevious}
                                       buttonSize="xlarge"
                                       isSkippable={false} isCurrentComplete isLoading={isSending} />
        </Stack>
      </section>
    </Container>
  );
};

export default OnboardingPhoneNumber;

OnboardingPhoneNumber.propTypes = {
  slice: PropTypes.any,
  context: PropTypes.object,
};
