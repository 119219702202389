import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';
import config from '../slicemachine.config.json';
import { getPaddingValue, getSpacingValue } from './theme/landing/spacing';

/**
 * The project's Prismic repository name.
 */
export const repositoryName = process.env.NEXT_PUBLIC_PRISMIC_ENVIRONMENT || config.repositoryName;

/**
 * A list of Route Resolver objects that define how a document's `url` field is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 *
 * @type {prismic.ClientConfig['routes']}
 */
// TODO: Update the routes array to match your project's route structure.
const routes = [
  // Examples:
  // {
  //   type: 'landing_page',
  //   path: '/prismic',
  // },
  {
    type: 'landing_page',
    path: '/server-side-tracking/:uid',
  },
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param {prismicNext.CreateClientConfig} prismicConfig - Configuration for the Prismic client.
 */
export const createPrismicClient = (prismicConfig = {}) => {
  const client = prismic.createClient(repositoryName, {
    routes,
    fetchOptions:
      process.env.NODE_ENV === 'production'
        ? { next: { tags: ['prismic'] }, cache: 'force-cache' }
        : { next: { revalidate: 5 } },
    ...prismicConfig,
  });

  prismicNext.enableAutoPreviews({
    client,
    previewData: prismicConfig.previewData,
    req: prismicConfig.req,
  });

  return client;
};

export const getSliceSettings = (slice, defaultSettings) => ({
  containerWidth: slice?.primary?.width || defaultSettings?.sliceContainerWidth || 'lg',
  slicePadding: getPaddingValue(slice?.primary?.padding || defaultSettings?.slicePadding || 'lg'),
  sliceSpacing: getSpacingValue(slice?.primary?.spacing || defaultSettings?.sliceSpacing || 'lg'),
  sliceVariant: slice?.primary?.variant || defaultSettings?.sliceVariant || 'light',
});

export async function getPrismicNavigation(prismicClient) {
  try {
    return await prismicClient.getByUID('navigation', 'server-side-tracking');
  } catch (error) {
    return [];
  }
}
