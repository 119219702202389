'use client';

import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Container, InputAdornment, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import * as PropTypes from 'prop-types';
import { useSnackbar } from 'src/components/snackbar';
import { axiosPost } from 'src/utils/axios';
import { fireCheckedUrl } from 'src/utils/tracking';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSearchParams } from 'src/routes/hooks';
import PrismicOnboardingNavigation from 'src/components/prismic/onboarding/prismic-onboarding-navigation';
import PrismicOnboardingHeader from 'src/components/prismic/onboarding/prismic-onboarding-header';
import PrismicOnboardingShare from 'src/components/prismic/onboarding/prismic-onboarding-share';

const guessingTexts = [
  'Checking the website...',
  'Perfoming magic...',
  'Analyzing the data...',
  'Crunching the numbers...',
];

// const ONBOARDING_URL = `${HOST_WEBSITE}/start`;
const DEFAULT_ONBOARDING_URL = `/start`;

let quessingTextInterval = null;
let isWebsiteOnNextCalled = false;

/**
 * @typedef {import('@prismicio/client').Content.OnboardingWebsiteSlice} OnboardingWebsiteSlice
 * @typedef {import('@prismicio/react').SliceComponentProps<OnboardingWebsiteSlice>} OnboardingWebsiteProps
 * @param {OnboardingWebsiteProps}
 */
const OnboardingWebsite = ({ slice, context }) => {
  const sx = null;
  const inputSx = null;
  const simple = false;
  const align = 'left';
  const variant = null;
  const onCmsGuessesChanged = context?.onCmsGuessesChanged;
  const onNext = context?.onNext;
  const onPrevious = context?.onPrevious;
  const clients = context?.clients;
  const onboardingUrl = context?.onboardingUrl;

  const { enqueueSnackbar } = useSnackbar();
  const mdUp = useResponsive('up', 'md');
  const searchParams = useSearchParams();

  const [isGuessing, setIsGuessing] = useState(false);
  const [guessingTextIndex, setGuessingTextIndex] = useState(0);

  const [websiteUrl, setWebsiteUrl] = useState('');
  const [selectedClientId, setSelectedClientId] = useState(null);

  useEffect(() => {
    const currentCheckUrl = searchParams.get('url');
    if (currentCheckUrl && !isWebsiteOnNextCalled) {
      isWebsiteOnNextCalled = true;
      onNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCmsGuessedRedirect = (guesses, url, clientId) => {
    const urlParams = new URLSearchParams({ url, guesses, client: clientId || '' }).toString();

    window.location.href = `${onboardingUrl || DEFAULT_ONBOARDING_URL}?${urlParams}`;
  };

  const onSubmitWebsiteUrl = async () => {
    const url = `/network/check-url`;
    setIsGuessing(true);

    try {
      const { success, guesses, checkUrl } = await axiosPost(url, {
        checkUrl: websiteUrl,
      });
      if (success) {
        if (onCmsGuessesChanged) {
          onCmsGuessesChanged(guesses, checkUrl, selectedClientId);
        }
        if (simple) {
          handleCmsGuessedRedirect(guesses, checkUrl, selectedClientId);
        }
        fireCheckedUrl();
        // enqueueSnackbar(message, { variant: 'success', autoHideDuration: 10000 });
      }
      setIsGuessing(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(typeof error === 'string' ? error : error.message, {
        variant: 'error',
        autoHideDuration: 10000,
      });
      setIsGuessing(false);

      onNext();
    }
  };

  useEffect(() => {
    if (!selectedClientId && clients?.length > 0) {
      setSelectedClientId(clients[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  useEffect(() => {
    if (!isGuessing) {
      clearInterval(quessingTextInterval);
    }
    quessingTextInterval = setInterval(() => {
      setGuessingTextIndex((prevState) => (prevState + 1) % guessingTexts.length);
    }, 3000);
    return () => clearInterval(quessingTextInterval);
  }, [isGuessing]);

  const useShortInput = !(mdUp || !clients?.length);

  const renderWebsiteStep = (
    <Stack spacing={3.5} sx={sx}>
      <Stack spacing={3}>
        {!simple && (
          <PrismicOnboardingHeader
            title={slice.primary.title}
            description={slice.primary.description} />
        )}
        <Stack spacing={0.75}>
          {!simple && (
            <Stack direction='row' justifyContent='space-between'>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: simple ? '1.3rem' : '1.1rem', mb: simple ? 0.5 : 0 }}
              >
                {slice.primary.input_title || 'Where do you want to install Tracklution?'}
              </Typography>
              {false && <PrismicOnboardingShare />}
            </Stack>
          )}
          <TextField
            size="large"
            defaultValue={websiteUrl}
            onChange={(event) => {
              if (isGuessing) {
                return;
              }
              setWebsiteUrl(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
                onSubmitWebsiteUrl();
              }
            }}
            focused
            name="url"
            fullWidth
            placeholder="e.g. tracklution.com"
            InputProps={{
              startAdornment: (
                <Stack direction="row" alignItems="center">
                  {!useShortInput && (
                    <InputAdornment position="start" sx={{ mt: -0.25, ml: 0.5, mr: 1 }}>
                      <Iconify
                        icon="solar:global-outline"
                        sx={{ width: 22, height: 22, color: 'accent.main' }}
                      />
                    </InputAdornment>
                  )}
                  {clients?.length > 1 && (
                    <Select
                      name="client_id"
                      value={selectedClientId}
                      onChange={(event) => setSelectedClientId(event.target.value)}
                      sx={{
                        mx: useShortInput ? -0.5 : 0.5,
                        width: 'auto',
                        maxWidth: useShortInput ? 100 : 200,
                        bgcolor: (theme) => `${theme.palette.background.dark}!important`,
                        pl: 2,
                        pr: '0!important',
                        py: 0.25,
                        borderRadius: '8px!important',
                        '& .MuiSelect-select': {
                          minHeight: 0,
                          py: 0,
                          pl: 0,
                          pr: useShortInput ? '16px!important' : 4,
                        },
                        '& fieldset': {
                          border: 'none',
                        },
                      }}
                    >
                      {clients?.map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                          {client.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Stack>
              ),
              endAdornment: simple && (
                <Button
                  size="default"
                  variant="contained"
                  sx={{
                    px: useShortInput ? 1 : 3.5,
                    mr: -0.75,
                    ml: 0.75,
                    fontSize: '1.05rem',
                    minWidth: 30,
                  }}
                  color="accent"
                  disabled={isGuessing}
                  onClick={onSubmitWebsiteUrl}
                >
                  {!useShortInput ? (
                    'Start'
                  ) : (
                    <Iconify icon="solar:alt-arrow-right-bold" width={{ xs: 20, md: 48 }} />
                  )}
                </Button>
              ),
            }}
            sx={{
              pointerEvents: isGuessing ? 'none' : 'auto',
              minWidth: 150,
              '& .MuiInputBase-input': {
                typography: 'body1',
                pl: 1.25,
                borderTopLeftRadius: '0!important',
                borderBottomLeftRadius: '0!important',
              },
              '& .MuiInputBase-root': {
                bgcolor: 'background.paper',
              },
              ...inputSx,
            }}
          />
          {isGuessing && (
            <Stack>
              <LinearProgress color="accent" sx={{ width: 1, height: 10, mr: -1 }} />
              <Typography
                variant="body2"
                sx={{ mt: 0.125, mb: -1.25, color: variant === 'dark' ? '#ffffff' : 'inherit' }}
              >
                {guessingTexts[guessingTextIndex]}
              </Typography>
            </Stack>
          )}
          {!isGuessing && !simple && (
            <Typography
              variant="body2"
              sx={{
                mt: 0.125,
                textAlign: { xs: 'left', md: align === 'center' ? 'center' : 'left' },
              }}
            >
              {simple
                ? 'Enter a valid URL.'
                : slice.primary.input_description ||
                'We\'ll use the URL to personalize your setup process.'}
            </Typography>
          )}
        </Stack>
      </Stack>
      {!simple && (
        <PrismicOnboardingNavigation onNext={onSubmitWebsiteUrl} onPrevious={onPrevious} isSkippable={false} isCurrentComplete={websiteUrl} buttonSize="xlarge"
                                       nextLabel={slice.primary.next_button_title || 'Continue'}
                                       contactButtonLabel="Agency? Start here »" />
      )}
    </Stack>
  );

  if (simple) {
    return (
      <section data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
        {renderWebsiteStep}
      </section>
    );
  }

  return (
    <Container maxWidth="md">
      <section data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
        {renderWebsiteStep}
      </section>
    </Container>
  );
};

export default OnboardingWebsite;
OnboardingWebsite.propTypes = {
  slice: PropTypes.any,
  context: PropTypes.object,
};
