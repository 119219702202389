import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// icons
// @mui
import Image from 'next/image';
import { Box } from '@mui/material';
import Iconify from './iconify';
import { getSocial } from '../../theme/constants';

// ----------------------------------------------------------------------

const SocialIcon = forwardRef(({ alias, width = 20, sx, ...other }, ref) => {
  const social = getSocial(alias);
  if (!social) {
    return null;
  }
  if (social.value === 'snapchat') {
    return (
      <Box sx={{ ...sx }}>
        <Image
          src="/assets/images/snapchat-logo.svg"
          alt="Snapchat"
          width={width}
          height={width}
        />
      </Box>
    );
  }
  if (social.value === 'microsoft-ads') {
    return <Iconify icon="logos:microsoft-icon" sx={{ ...sx }} width={width} />;
  }
  if (social.value === 'pinterest') {
    return <Iconify icon="mdi:pinterest" sx={{ color: social.color, ...sx }} width={width} />;
  }
  return <Iconify icon={social.icon} sx={{ color: social.color, ...sx }} width={width} />;
});
SocialIcon.propTypes = {
  alias: PropTypes.string,
  sx: PropTypes.object,
  width: PropTypes.number,
};

export default SocialIcon;
