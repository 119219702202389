'use client';

import { Avatar, Box } from '@mui/material';
import { Stack } from '@mui/system';
import { Masonry } from '@mui/lab';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
import PrismicTypography from '../../components/prismic/prismic-typography';
import PrismicHeading from '../../components/prismic/prismic-heading';
import PrismicContainer from '../../components/prismic/prismic-container';
import { getSliceSettings } from '../../prismicio';

/**
 * @typedef {import('@prismicio/client').Content.TestimonialMasonrySlice} TestimonialMasonrySlice
 * @typedef {import('@prismicio/react').SliceComponentProps<TestimonialMasonrySlice>} TestimonialMasonryProps
 * @param {TestimonialMasonryProps}
 */
const TestimonialMasonry = ({ slice, context }) => {
  const { containerWidth, slicePadding, sliceSpacing, sliceVariant } = getSliceSettings(
    slice,
    context?.defaultSettings
  );

  return (
    <PrismicContainer
      slice={slice}
      width={containerWidth}
      slicePadding={slicePadding}
      sliceSpacing={sliceSpacing}
      variant={sliceVariant}
      disablePaddingBottom={slice.primary.is_bottom_padding_disabled}
    >
      <Stack spacing={{ xs: sliceSpacing.xs * 2.5, md: sliceSpacing.md * 2.5 }}>
        <PrismicHeading
          caption={slice.primary.caption}
          title={slice.primary.title}
          description={slice.primary.description}
          sliceSpacing={sliceSpacing}
          containerWidth={containerWidth}
          variant={sliceVariant}
        />
        <Masonry
          direction="row"
          columns={{ xs: 1, sm: 3 }}
          spacing={2}
          sx={{
            flexWrap: 'wrap',
            mx: 'auto',
            alignItems: 'center',
          }}
        >
          {slice.primary.testimonials?.map((testimonial, index) => (
            <TechTestimonial
              key={index}
              avatarUrl={testimonial.avatar?.url}
              description={testimonial.description}
              name={testimonial.author_name}
              company={testimonial.author_description}
              variant={sliceVariant}
            />
          ))}
        </Masonry>
      </Stack>
    </PrismicContainer>
  );
};

export default TestimonialMasonry;
TestimonialMasonry.propTypes = {
  slice: PropTypes.any,
  context: PropTypes.object,
};

// ----------------------------------------------------------------------

function TechTestimonial({ avatarUrl, description, name, company, variant }) {
  return (
    <Card
      sx={{
        backgroundColor:
          variant === 'dark' ? 'hsl(240deg 12.3% 49.21% / 21%)' : 'background.default',
        m: 1,
        py: 3,
        px: 4,
        borderRadius: 2,
        boxShadow: `rgba(145, 158, 171, 0.08) 0px 10px 30px 0px`,
        border: `1px dashed rgba(145, 158, 171, 0.3)`,
        // maxWidth: { xs: '100%', md: 300 },
      }}
      spacing={1}
    >
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <Avatar sx={{ width: 80, height: 80, mt: 2, mb: 1 }} src={avatarUrl} />
        <PrismicTypography
          variant="subtitle2"
          textAlign="center"
          sx={{ fontWeight: 500 }}
          colorVariant={variant}
        >
          {description}
        </PrismicTypography>
        <Stack>
          <PrismicTypography
            variant="body2"
            textAlign="center"
            sx={{ fontWeight: 500, fontSize: '0.95rem' }}
            colorVariant={variant}
          >
            {name}
          </PrismicTypography>
          <PrismicTypography
            variant="body2"
            textAlign="center"
            color="text.secondary"
            colorVariant={variant}
          >
            {company}
          </PrismicTypography>
        </Stack>
      </Stack>
    </Card>
  );
}

TechTestimonial.propTypes = {
  avatarUrl: PropTypes.string,
  description: PropTypes.any,
  name: PropTypes.string,
  company: PropTypes.string,
  variant: PropTypes.string,
};

// ----------------------------------------------------------------------

function HighlightedText({ children }) {
  return (
    <Box component="span" sx={{ color: 'accent.main' }}>
      {children}
    </Box>
  );
}

HighlightedText.propTypes = {
  children: PropTypes.node,
};
