import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import PrismicTypography from '../prismic-typography';
// @mui
//

// ----------------------------------------------------------------------

export default function PrismicOnboardingHeader({
                                                  title,
                                                  description,
                                                }) {

  return <Stack direction="column" justifyContent="space-between" spacing={1}>
      <Stack spacing={1.5}>
        {false && <Typography variant="overline">Choose website</Typography>}
        {title && <Typography
          variant="h3"
          sx={{
            fontSize: { xs: '2.1rem!important', md: '2.5rem!important' },
            lineHeight: 1.2,
          }}
        >
          {title}
        </Typography>}
      </Stack>
      {description && <PrismicTypography variant="body1">{description}</PrismicTypography>}
    </Stack>;
}


PrismicOnboardingHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

