'use client';

import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';
import Iconify from '../iconify';
import Link from '../link/link';
import { HEADER } from '../../layouts/config-layout';
import { isString } from '../../utils/general';

PrismicTableOfContents.propTypes = {
  items: PropTypes.array,
  variant: PropTypes.string,
  sliceSpacing: PropTypes.object,
};

export default function PrismicTableOfContents({ items, variant, sliceSpacing }) {
  function scrollToTop() {
    if (typeof window === 'undefined') return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Box sx={{ position: 'sticky', top: `calc(${HEADER.H_MOBILE + sliceSpacing.md * 8 * 4}px)` }}>
      <Card
        sx={{
          backgroundColor:
            variant === 'dark' ? 'hsl(240deg 12.3% 49.21% / 21%)' : 'background.default',
          m: 3,
          mt: 0,
          ml: 0,
          py: 4,
          px: 4,
          borderRadius: 2,
          boxShadow: `none`,
          border: `1px dashed rgba(145, 158, 171, 0.3)`,
        }}
        spacing={1}
      >
        <Stack spacing={{ xs: sliceSpacing.xs / 2, md: sliceSpacing.md / 1.25 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              Table of contents
            </Typography>
            <IconButton
              color="inherit"
              variant="outlined"
              size="small"
              sx={{ mr: -1, my: -1 }}
              onClick={() => scrollToTop()}
            >
              <Iconify icon="solar:arrow-up-outline" />
            </IconButton>
          </Stack>
          {items?.map((item, index) => (
            <PrismicTableOfContentsItem key={index} item={item} />
          ))}
        </Stack>
      </Card>
    </Box>
  );
}

// ----------------------------------------------------------------------

PrismicTableOfContentsItem.propTypes = {
  item: PropTypes.object,
};

function PrismicTableOfContentsItem({ item }) {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const isTargetInView = () => {
      if (typeof window === 'undefined' || !isString(item.link)) {
        return false;
      }
      const linkId = getAnchorLink(item.link).replace('#', '');
      const target = document.getElementById(linkId);
      if (!target) {
        return false;
      }
      const bounding = target.getBoundingClientRect();
      const targetInView = bounding.top >= 0 && bounding.bottom <= window.innerHeight;

      if (targetInView) {
        setIsInView(true);
      }
      return true;
    };

    isTargetInView();
    window.addEventListener('scroll', isTargetInView);

    return () => {
      window.removeEventListener('scroll', isTargetInView);
    };
  }, [item.link]);

  const getAnchorLink = (href) => {
    if (!isString(href)) {
      return null;
    }
    if (href.startsWith('#') || href.includes('/')) {
      return href;
    }
    return `#${href}`;
  };

  const validLink = getAnchorLink(item.link);

  if (!validLink) {
    return <Box sx={{ color: 'error.main' }}>Invalid link: {item.title}</Box>;
  }

  return (
    <Link
      href={validLink}
      sx={{
        color: 'text.primary',
        fontSize: '0.975rem',
        fontWeight: 500,
        lineHeight: 1.4,
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Box
          sx={{
            width: 6,
            minWidth: 6,
            height: 6,
            borderRadius: '50%',
            bgcolor: isInView ? 'accent.main' : 'text.secondary',
            opacity: isInView ? 1 : 0.6,
          }}
        />
        <Stack spacing={0.25}>
          <Box>{item.title}</Box>
          <Typography color="text.secondary" variant="body2" sx={{ lineHeight: 1.4 }}>
            {item.description}
          </Typography>
        </Stack>
      </Stack>
    </Link>
  );
}
