'use client';

import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import PrismicTypography from './prismic-typography';
import Iconify from '../iconify';

PrismicToggleableContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.any,
  spacing: PropTypes.object,
  variant: PropTypes.string,
};

export default function PrismicToggleableContent({ title, description, spacing, variant }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={{ xs: spacing.xs, md: spacing.md / 1.5 }}
        sx={{ cursor: 'pointer', py: { xs: spacing.xs, md: spacing.md / 1.5 }, mr: 'auto' }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Iconify
          icon={isOpen ? 'solar:alt-arrow-up-linear' : 'solar:alt-arrow-down-linear'}
          width={{ xs: 24, md: 24 }}
          sx={{ mt: 0.5, color: variant === 'dark' ? 'common.white' : 'text.primary' }}
        />
        <PrismicTypography variant="h3" colorVariant={variant}>
          {title}
        </PrismicTypography>
      </Stack>
      {isOpen && (
        <PrismicTypography
          variant="body1"
          colorVariant={variant}
          sx={{
            pt: { xs: spacing.xs / 3, md: spacing.md / 3 },
            pb: { xs: spacing.xs / 2, md: spacing.md / 1 },
          }}
        >
          {description}
        </PrismicTypography>
      )}
    </Stack>
  );
}
