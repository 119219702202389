import React, { useState } from 'react';
import { Slider, Stack, Card } from '@mui/material';
import PropTypes from 'prop-types';

const minVal = 10000;
const maxVal = 100000000;

const logScale = (value) => Math.log10(value);
const inverseLogScale = (logValue) => 10**logValue;

// Funktio, joka pyöristää järkeviksi 1, 1.5, 2, 3, 5, 7.5, 10 steppeihin
const roundToSignificant = (value) => {
  const exponent = Math.floor(Math.log10(value));
  const base = value / 10**exponent;

  let roundedBase;
  if (base < 1.25) roundedBase = 1;
  else if (base < 1.75) roundedBase = 1.5;
  else if (base < 2.5) roundedBase = 2;
  else if (base < 4) roundedBase = 3;
  else if (base < 6) roundedBase = 5;
  else if (base < 8.5) roundedBase = 7.5;
  else roundedBase = 10;

  return roundedBase * 10**exponent;
};

const PrismicSlider = ({ onChange, color = 'primary' }) => {
  const [selectedEventCount, setEventCount] = useState(500000);

  const handleSliderChange = (_, newValue) => {
    const realValue = inverseLogScale(newValue);
    const roundedValue = roundToSignificant(realValue);
    setEventCount(roundedValue);

    onChange?.(roundedValue);
  };

  return (
    <Stack spacing={2}>
      <Slider
        value={logScale(selectedEventCount)}
        onChange={handleSliderChange}
        min={logScale(minVal)}
        max={logScale(maxVal)}
        step={0.05} // Tiheämpi step, jotta lisästepit toimivat paremmin
        valueLabelDisplay="auto"
        valueLabelFormat={(val) => roundToSignificant(inverseLogScale(val)).toLocaleString()}
        color={color}
      />
      <Card sx={{ p: 2, textAlign: 'center' }}>
        <strong>Selected Events:</strong> {selectedEventCount.toLocaleString()}
      </Card>
    </Stack>
  );
};

export default PrismicSlider;
PrismicSlider.propTypes = {
  onChange: PropTypes.func,
  color: PropTypes.string,
};
