'use client';

import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import * as PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import PrismicTypography from 'src/components/prismic/prismic-typography';
import PrismicSlider from 'src/components/prismic/prismic-slider';
import { axiosPost } from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import { Label } from 'src/components/landing';

const EVENT_PLANS = [
  {
    title: 'Starter',
    alias: 'starter',
    basePrice: 39,
    eventPrices: [
      {
        fromEventCount: 50000,
        pricePerThousand: 0.3,
      },
      {
        fromEventCount: 500000,
        pricePerThousand: 0.23,
      },
      {
        fromEventCount: 5000000,
        pricePerThousand: 0.1,
      },
    ],
  },
  {
    title: 'Plus',
    alias: 'plus',
    basePrice: 169,
    eventPrices: [
      {
        fromEventCount: 500000,
        pricePerThousand: 0.15,
      },
      {
        fromEventCount: 5000000,
        pricePerThousand: 0.10,
      },
      {
        fromEventCount: 10000000,
        pricePerThousand: 0.06,
      },
    ],
  },
  {
    title: 'Pro',
    alias: 'pro',
    basePrice: 549,
    eventPrices: [
      {
        fromEventCount: 3000000,
        pricePerThousand: 0.1,
      },
      {
        fromEventCount: 10000000,
        pricePerThousand: 0.06,
      },
      {
        fromEventCount: 50000000,
        pricePerThousand: 0.03,
      },
    ],
  },
];

/**
 * @typedef {import('@prismicio/client').Content.PricingCardsSlice} PricingCardsSlice
 * @typedef {import('@prismicio/react').SliceComponentProps<PricingCardsSlice>} PricingCardsProps
 * @param {PricingCardsProps}
 */
const PricingCards = ({ slice, context }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [productAlias, setProductAlias] = useState('plus');
  const [billingInterval, setBillingInterval] = useState('year');
  const [selectedTrackingTagId, setSelectedTrackingTagId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [eventCount, setEventCount] = useState(500000);
  const [totalPrices, setTotalPrices] = useState(null);
  const [discountPercent, setDiscountPercent] = useState(0);

  useEffect(() => {
    const selectedPlan = EVENT_PLANS.find((plan) => plan.alias === productAlias);
    if (selectedPlan) {
      // eslint-disable-next-line no-restricted-syntax
      for (const plan of EVENT_PLANS) {
        const totalPrice = calculateTotalPrice(plan, eventCount);
        setTotalPrices((prev) => ({ ...prev, [plan.alias]: totalPrice }));
      }
    }
  }, [productAlias, eventCount]);

  useEffect(() => {
    setDiscountPercent(billingInterval === 'year' ? getDiscountPercent(eventCount) : 0);
  }, [billingInterval, eventCount]);

  const getDiscountPercent = (currentEventCount) => {
    const minEvents = 50000;
    const maxEvents = 100000000;
    const minDiscount = 10; // 10%
    const maxDiscount = 40; // 50%

    const clampedEventCount = Math.max(minEvents, Math.min(maxEvents, currentEventCount));

    const logMin = Math.log10(minEvents);
    const logMax = Math.log10(maxEvents);
    const logEventCount = Math.log10(clampedEventCount);

    const normalized = ((logEventCount - logMin) / (logMax - logMin)) ** 1.5;

    const discount = minDiscount + (maxDiscount - minDiscount) * normalized;

    return discount.toFixed(0);
  };

  const handleEventCountChange = (newEventCount) => {
    setEventCount(newEventCount);
  };

  const calculateTotalPrice = (config, currentEventCount) => {
    let totalPrice = config.basePrice;
    const remainingEvents = currentEventCount;

    const sortedPrices = [...config.eventPrices].sort((a, b) => a.fromEventCount - b.fromEventCount);

    for (let i = 0; i < sortedPrices.length && remainingEvents > 0; i += 1) {
      const { fromEventCount, pricePerThousand } = sortedPrices[i];
      const nextThreshold = sortedPrices[i + 1]?.fromEventCount || Infinity;

      if (remainingEvents > fromEventCount) {
        const billableEvents = Math.min(remainingEvents, nextThreshold) - fromEventCount;
        totalPrice += (billableEvents / 1000) * pricePerThousand;
      }
    }

    return totalPrice;
  };

  const handleChangeBillingInterval = (event, newBillingInterval) => {
    setBillingInterval(newBillingInterval);
  };

  const handleChangeSubscription = async () => {
    const url = '/network/dynamic-subscription/change-subscription';

    setIsLoading(true);

    try {
      const response = await axiosPost(url, {
        productAlias,
        billingInterval,
        trackingTagId: selectedTrackingTagId,
      });

      if (response?.success) {
        enqueueSnackbar('Subscription update successful!', { variant: 'success' });
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error updating subscription', { variant: 'error' });
      setIsLoading(false);
    }
  };


  return (
    <section data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
      <Stack sx={{ minHeight: '80vh', alignItems: 'center', justifyContent: 'center', my: 10, px: 3 }}>

        <Stack spacing={4}>
          <Stack>
            <ToggleButtonGroup
              exclusive
              value={billingInterval}
              onChange={handleChangeBillingInterval}
              variant="contained"
              color={discountPercent > 0 ? 'accent' : 'primary'}
            >
              <ToggleButton value="year">
                Yearly
              </ToggleButton>
              <ToggleButton value="month">
                Monthly
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <PrismicSlider onChange={handleEventCountChange} color={discountPercent > 0 ? 'accent' : 'primary'} />

          <Stack direction="row" spacing={2} flexWrap="wrap">
            {EVENT_PLANS.map((plan) => (
              <Card
                elevation={2}
                key={plan.alias}
                sx={{
                  p: 2,
                  borderRadius: 2,
                  border: (theme) => productAlias === plan.alias ? `2px solid ${discountPercent > 0 ? theme.palette.accent.main : theme.palette.primary.main}` : '2px solid transparent',
                  cursor: 'pointer',
                  minWidth: 200,
                  boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.5),0 12px 24px -4px rgba(145, 158, 171, 0.3)',
                  width: { xs: '100%', md: 'auto' },
                }}
                onClick={() => setProductAlias(plan.alias)}
              >
                <Stack direction="column" spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                      <PrismicTypography variant="h3">{plan.title}</PrismicTypography>
                      {discountPercent > 0 && <Label
                        sx={{
                          bgcolor: 'accent.main',
                          color: 'white',
                          height: 28,
                          paddingLeft: 1.2,
                          paddingRight: 1.2,
                          borderRadius: 1.5,
                          fontSize: '1.1rem',
                        }}
                      >
                        -{discountPercent} %
                      </Label>}
                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                      <PrismicTypography variant="subtitle1">{`$${plan.basePrice}`}</PrismicTypography>
                      {plan?.eventPrices?.[0] && <PrismicTypography
                        variant="body2">{`Incl. ${plan?.eventPrices?.[0].fromEventCount.toLocaleString()} events`}</PrismicTypography>}
                    </Stack>
                  </Stack>
                  <Stack>
                    {plan.eventPrices.map((priceConfig, index) => (
                      <Stack direction="row" spacing={2} justifyContent="space-between" key={index}>
                        <PrismicTypography variant="body2">{`From ${priceConfig.fromEventCount.toLocaleString()} events`}</PrismicTypography>
                        <PrismicTypography variant="body2">{`${priceConfig.pricePerThousand} €/k`}</PrismicTypography>
                      </Stack>
                    ))}
                  </Stack>
                  {totalPrices?.[plan.alias] && <Stack direction="row">
                    <Stack>
                      <PrismicTypography variant="subtitle1">Total Price</PrismicTypography>
                      <Stack direction="row" spacing={1} alignItems="end">
                        {discountPercent > 0 &&
                          <PrismicTypography variant="h3"
                                             color="accent.main">{`${Math.round(totalPrices[plan.alias] * ((100 - discountPercent) / 100))} €`}</PrismicTypography>}
                        <PrismicTypography variant="h3" color="primary.main" sx={{
                          ...(discountPercent > 0 && {
                            color: 'text.disabled',
                            fontSize: '1.2rem!important',
                            fontWeight: 400,
                            position: 'relative',
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              top: '50%',
                              height: '1px',
                              backgroundColor: 'text.disabled',
                              // transform: 'rotate(-2deg)',
                            },
                          }),
                        }}>{`${totalPrices[plan.alias]} €`}</PrismicTypography>
                      </Stack>
                    </Stack>
                  </Stack>
                  }
                </Stack>
              </Card>
            ))}
          </Stack>
          <Stack>
            <Button color={discountPercent > 0 ? 'accent' : 'primary'} onClick={handleChangeSubscription} disabled={isLoading}
                    sx={{ mr: 'auto', mt: 1 }}>
              {isLoading ? 'Loading...' : 'Change Subscription'}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </section>
  );
};

export default PricingCards;
PricingCards.propTypes = {
  slice: PropTypes.any,
  context: PropTypes.object,
};

