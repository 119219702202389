import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import PrismicTypography from './prismic-typography';
import { getMaxHeadingWidth, getMaxWidth } from '../../theme/landing/spacing';

PrismicHeading.propTypes = {
  caption: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.any,
  sliceSpacing: PropTypes.object,
  containerWidth: PropTypes.string,
  variant: PropTypes.string,
  sx: PropTypes.object,
};

export default function PrismicHeading({
  caption,
  title,
  description,
  sliceSpacing,
  containerWidth,
  variant,
  sx,
}) {
  const textAlign = sx?.textAlign || { xs: 'left', md: 'center' };
  const isCenter = textAlign?.md === 'center';

  const variantSx = variant === 'dark' ? { color: 'common.white' } : {};

  return (
    <Stack
      spacing={{ xs: sliceSpacing.xs, md: sliceSpacing.md / 1.5 }}
      sx={{
        textAlign,
        display: 'flex',
        flexDirection: 'column',
        ...variantSx,
        maxWidth: `${isCenter ? getMaxHeadingWidth(containerWidth) : getMaxWidth(containerWidth)}px!important`,
        margin: isCenter ? 'auto' : 0,
        ...sx,
      }}
    >
      {caption && (
        <PrismicTypography variant="h5" sx={{ mb: -0.25 }}>
          {caption}
        </PrismicTypography>
      )}
      {title && <PrismicTypography variant="h2">{title}</PrismicTypography>}
      {description && <PrismicTypography variant="body1" colorVariant={variant}>{description}</PrismicTypography>}
    </Stack>
  );
}
