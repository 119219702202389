import PropTypes from 'prop-types';
// icons
// next
import NextLink from 'next/link';
// @mui
import {Box, Link, Breadcrumbs as MUIBreadcrumbs, Hidden} from '@mui/material';
//
import Iconify from './Iconify';

// ----------------------------------------------------------------------

LinkList.propTypes = {
  activeLast: PropTypes.bool,
  links: PropTypes.array.isRequired,
  onDark: PropTypes.bool,
};

export default function LinkList({links, onDark = false, ...other}) {
  const listDefault = links.map((link) => <LinkItem key={link.name} link={link} onDark={onDark}
                                                    sx={{fontWeight: link.current ? '600!important' : '400!important'}}/>);

  return (
    <MUIBreadcrumbs
      separator={
        <Hidden smDown implementation='css'><Box sx={{width: 16}}><Iconify
          icon='ph:dot-duotone'
          sx={{
            width: 16,
            height: 16,
            ...(onDark && {
              opacity: 0.48,
              color: 'common.white',
            }),
          }}
        /></Box></Hidden>
      }
      {...other}
    >
      {listDefault}
    </MUIBreadcrumbs>
  );
}

// ----------------------------------------------------------------------

LinkItem.propTypes = {
  link: PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.any,
    name: PropTypes.string,
    xsName: PropTypes.string
  }),
  onDark: PropTypes.bool,
  sx: PropTypes.object
};

function LinkItem({link, onDark, sx}) {
  const {href = '', name, xsName, icon} = link;
  return (
    <NextLink key={name} href={href} passHref legacyBehavior>
      <Link
        variant="body3"
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'text.primary',
          ...(onDark && {
            color: 'common.white',
          }),
          ...sx
        }}
      >
        {icon && (
          <Box
            sx={{
              mr: 1,
              '& svg': {width: 20, height: 20},
            }}
          >
            {icon}
          </Box>
        )}
        <Hidden smDown implementation='css'>{name}</Hidden>
        <Hidden smUp implementation='css'>{xsName}</Hidden>
      </Link>
    </NextLink>
  );
}
