'use client';

import Card from '@mui/material/Card';
import { Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from 'src/components/landing/Iconify';
import PrismicContainer from 'src/components/prismic/prismic-container';
import { getSliceSettings } from 'src/prismicio';
import PrismicHeading from 'src/components/prismic/prismic-heading';
import ContactFormCard from 'src/sections/contact/contact-form';
import PrismicTypography from '../../components/prismic/prismic-typography';

/**
 * @typedef {import('@prismicio/client').Content.ContactFormSlice} ContactFormSlice
 * @typedef {import('@prismicio/react').SliceComponentProps<ContactFormSlice>} ContactFormProps
 * @param {ContactFormProps}
 */
const ContactForm = ({ slice, context }) => {
  const { containerWidth, slicePadding, sliceSpacing, sliceVariant } = getSliceSettings(
    slice,
    context?.defaultSettings,
  );

  if (!slice) {
    return null;
  }

  const renderContact = (
    <Card
      sx={{
        pt: { xs: 3, md: 4 },
        pb: { xs: 3, md: 4 },
        px: { xs: 3, md: 4 },
        boxShadow: (theme) => ({ md: theme.customShadows.z8 }),
      }}
    >
      <Stack
        spacing={1.5}
        direction="row"
        alignItems="start"
        sx={{ mb: { xs: 0.5, md: 2.5 } }}
        justifyContent="space-between"
      >
        <Typography
          variant="h3"
          color="accent.main"
          sx={{ lineHeight: 1.15, textAlign: 'center', mt: 2.5 }}
        >
          {slice.primary.form_title}
        </Typography>
        <Iconify
          icon="solar:call-chat-bold-duotone"
          sx={{ width: { xs: 40, md: 50 }, height: { xs: 40, md: 50 }, opacity: 0.8 }}
          color="text.secondary"
        />
      </Stack>
      {slice.primary.form_description && <PrismicTypography variant="body1" sx={{ mb: 1 }}>{slice.primary.form_description}</PrismicTypography>}
      <Grid item xs={12} md={12} sx={{ pt: 2, pb: 1, alignItems: 'center', display: 'flex' }}>
        <ContactFormCard fullButton />
      </Grid>
    </Card>
  );

  const colorSx = sliceVariant === 'dark' ? { color: 'common.white' } : {};

  const renderContactInformation = (
    <Stack spacing={{ xs: 2, md: 3 }} sx={{ minWidth: 250, mx: { md: 0 }, mb: 6 }}>
      <Stack spacing={3} direction="row">
        <Iconify icon="solar:letter-linear" sx={{ width: 24, height: 24, ...colorSx }} />
        <Typography variant="h5" sx={{ ...colorSx }}>hello@tracklution.com</Typography>
      </Stack>
      <Stack spacing={3} direction="row">
        <Iconify icon="solar:phone-calling-rounded-linear" sx={{ width: 24, height: 24, ...colorSx }} />
        <Typography variant="h5" sx={{ ...colorSx }}>+358 505025140</Typography>
      </Stack>
      <Stack spacing={3} direction="row">
        <Iconify icon="solar:buildings-3-linear" sx={{ width: 24, height: 24, mb: 'auto', ...colorSx }} />
        <Stack>
          <Typography variant="h5" sx={{ mb: 1, mt: { xs: 0, md: -0.4 }, ...colorSx  }}>
            Tracklution Oy
          </Typography>
          <Typography variant="body1" sx={{ ...colorSx }}>Mannerheiminaukio 1</Typography>
          <Typography variant="body1" sx={{ ...colorSx }}>00100 Helsinki</Typography>
          <Typography variant="body1" sx={{ ...colorSx }}>Finland</Typography>

          <Typography variant="body1" sx={{ mt: 3, ...colorSx }}>
            FI27754355
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <PrismicContainer
      slice={slice}
      width={containerWidth}
      slicePadding={slicePadding}
      sliceSpacing={sliceSpacing}
      variant={sliceVariant}
      disablePaddingBottom={slice.primary.is_bottom_padding_disabled}
      sx={{
        backgroundColor: 'background.neutral',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Stack spacing={6}>
            <Stack spacing={{ xs: sliceSpacing.xs * 2, md: sliceSpacing.md * 2 }}>
              <PrismicHeading
                caption={slice.primary.caption}
                title={slice.primary.title}
                description={slice.primary.description}
                sliceSpacing={sliceSpacing}
                containerWidth={containerWidth}
                sx={{ textAlign: { xs: 'left', md: 'left' } }}
                variant={sliceVariant}
                tableOfContents={context?.tableOfContents}
              />
            </Stack>
            {renderContactInformation}
          </Stack>
        </Grid>

        <Grid item xs={12} md={5}>
          {renderContact}
        </Grid>
      </Grid>
    </PrismicContainer>
  );
};

export default ContactForm;
ContactForm.propTypes = {
  slice: PropTypes.any,
  context: PropTypes.object,
};
