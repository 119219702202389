'use client';

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import Stack from '@mui/material/Stack';
import Image from '../image';
import { fireWatchDemo, fireWatchDemoCompleted, fireWatchDemoPartial } from '../../utils/tracking';

const videoWatchedPartialSeconds = 60;
const videoWatchedCompleteSeconds = 180;

let videoWatchedPartialTimer = null;
let videoWatchedCompleteTimer = null;

PrismicVideo.propTypes = {
  src: PropTypes.string,
};

export default function PrismicVideo({ src }) {
  const [openVideo, setOpenVideo] = useState(false);

  const handleOpenVideo = () => {
    setOpenVideo(true);
    fireWatchDemo();

    clearTimeout(videoWatchedPartialTimer);
    videoWatchedPartialTimer = setTimeout(() => {
      fireWatchDemoPartial();
    }, 1000 * videoWatchedPartialSeconds);

    clearTimeout(videoWatchedCompleteTimer);
    videoWatchedCompleteTimer = setTimeout(() => {
      fireWatchDemoCompleted();
    }, 1000 * videoWatchedCompleteSeconds);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
    clearTimeout(videoWatchedPartialTimer);
    clearTimeout(videoWatchedCompleteTimer);
  };

  return <>
    <Stack sx={{ width: { xs: 'calc(100% + 4px)', md: '100%', cursor: 'pointer' }, pl: { xs: 0, md: 0 }, ml: { xs: -0.6, md: 0 } }}>
      <Image src='/assets/images/hero/tracklution-2024-dashboard-screenshot-watch-video.png' onClick={handleOpenVideo}
             sx={{ width: { xs: 'calc(100% + 4px)', md: '100%' }, maxWidth: 1080, mx: 'auto', '&:hover': { opacity: 0.95 }, mt: 0 }} />
    </Stack>
    <Dialog fullWidth maxWidth='lg' open={openVideo} onClose={handleCloseVideo} PaperProps={{
      sx: { background: '#000', overflow: 'hidden', aspectRatio: 16 / 9, m: 0, width: 'calc(100% - 16px)' },
    }}>
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          src={src}
          frameBorder='0'
          allow='autoplay; fullscreen; picture-in-picture; clipboard-write'
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          title='Install Server Side Tracking in few minutes with Tracklution'
        />
      </div>
    </Dialog>

  </>;
};
