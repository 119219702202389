'use client';

import { Hidden, Stack } from '@mui/material';
import Slider from 'react-slick';
import { useTheme } from '@mui/material/styles';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Iconify from '../../components/iconify';
import { CONNECTORS } from '../../theme/constants';
import { CarouselArrows, CarouselDots, Image } from '../../components/landing';
import { useSignupContext } from '../../components/signup-popup/context/signup-context';
import SocialIcon from '../../components/iconify/social-icon';
import PrismicHeading from '../../components/prismic/prismic-heading';
import PrismicContainer from '../../components/prismic/prismic-container';
import { getSliceSettings } from '../../prismicio';
import PrismicTypography from '../../components/prismic/prismic-typography';

// ----------------------------------------------------------------------

/**
 * @typedef {import('@prismicio/client').Content.ConnectorCarouselSlice} ConnectorCarouselSlice
 * @typedef {import('@prismicio/react').SliceComponentProps<ConnectorCarouselSlice>} ConnectorCarouselProps
 * @param {ConnectorCarouselProps}
 */
const ConnectorCarousel = ({ slice, context }) => {
  const { containerWidth, slicePadding, sliceSpacing, sliceVariant } = getSliceSettings(
    slice,
    context?.defaultSettings
  );

  const theme = useTheme();

  const carouselRef = useRef(null);

  const carouselSettings = {
    dots: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 2,
    adaptiveHeight: true,
    rtl: Boolean(theme.direction === 'rtl'),
    ...CarouselDots({
      rounded: true,
      sx: { mt: 5 },
    }),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.xl,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <PrismicContainer
      slice={slice}
      width={containerWidth}
      slicePadding={slicePadding}
      sliceSpacing={sliceSpacing}
      sx={{ position: 'relative', zIndex: 9 }}
      variant={sliceVariant}
      disablePaddingBottom={slice.primary.is_bottom_padding_disabled}
    >
      <Stack spacing={{ xs: sliceSpacing.xs * 1.5, md: sliceSpacing.md * 1.5 }}>
        <PrismicHeading
          caption={slice.primary.caption}
          title={slice.primary.title}
          description={slice.primary.description}
          sliceSpacing={sliceSpacing}
          containerWidth={containerWidth}
          variant={sliceVariant}
        />
        <Stack sx={{ position: 'relative' }}>
          <CarouselArrows
            onNext={handleNext}
            onPrevious={handlePrevious}
            sx={{
              '& .arrow': {
                mt: -2.5,
                '& button': {
                  mx: { xs: -0.5, md: -1.5 },
                  opacity: 1,
                  color: 'common.white',
                  bgcolor: 'accent.main',
                  '& svg': { width: 18, height: 18 },
                  '&:hover': {
                    opacity: 1,
                    bgcolor: 'accent.main',
                    color: 'common.white',
                  },
                },
              },
            }}
          >
            <Slider ref={carouselRef} {...carouselSettings}>
              {slice.primary.cards?.length > 0
                ? slice.primary.cards.map((card, index) => (
                    <ConnectorItem
                      key={index}
                      title={card.title}
                      description={card.description}
                      imageSrc={card.image?.url}
                      icon={card.icon}
                      buttonLabel={card.button_label}
                      isDemoButtonVisible={card.is_demo_button_visible}
                      variant={sliceVariant}
                    />
                  ))
                : CONNECTORS.map((connector) => (
                    <ConnectorItem
                      key={connector.value}
                      title={connector.name}
                      socialAlias={connector.value}
                      variant={sliceVariant}
                      buttonLabel='Get Started'
                      isDemoButtonVisible
                    />
                  ))}
            </Slider>
          </CarouselArrows>
        </Stack>
      </Stack>
    </PrismicContainer>
  );
};

export default ConnectorCarousel;
ConnectorCarousel.propTypes = {
  slice: PropTypes.any,
  context: PropTypes.object,
};

// ----------------------------------------------------------------------

ConnectorItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.any,
  imageSrc: PropTypes.string,
  icon: PropTypes.string,
  socialAlias: PropTypes.string,
  buttonLabel: PropTypes.string,
  isDemoButtonVisible: PropTypes.bool,
  variant: PropTypes.string,
};

function ConnectorItem({
  title,
  description,
  imageSrc,
  icon,
  socialAlias,
  buttonLabel,
  isDemoButtonVisible,
  variant,
}) {
  const signup = useSignupContext();

  const variantSx =
    variant === 'dark'
      ? {}
      : { boxShadow: '0 3px 5px 0 rgb(0 0 0 / 46%), inset -2px -8px 10px rgb(255 100 54 / 5%)' };

  return (
    <Stack
      sx={{
        bgcolor: 'background.default',
        p: 2,
        pt: 3,
        m: 1,
        borderRadius: 2,
        height: '100%',
        minHeight: 250,
        ...variantSx,
      }}
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Stack>
        {icon && <Iconify icon={icon} width={48} />}
        {imageSrc && <Image src={imageSrc} alt={title} sx={{ height: 56, m: -0.5 }} />}
        {socialAlias && <SocialIcon alias={socialAlias} width={48} />}
      </Stack>
      <Stack spacing={1.5}>
        {title && (
          <PrismicTypography color="inherit" variant="h4" sx={{ textAlign: 'center' }}>
            {title}
          </PrismicTypography>
        )}
        {description && (
          <PrismicTypography
            color="inherit"
            variant="body2"
            sx={{ textAlign: 'center', '& p': { fontSize: '0.875rem', m: 0 } }}
          >
            {description}
          </PrismicTypography>
        )}
        {(buttonLabel || isDemoButtonVisible) && (
          <Stack spacing={0.5}>
            {buttonLabel && (
              <Button
                color="accent"
                variant="contained"
                endIcon={
                  <Hidden implementation="css" smDown>
                    <Iconify icon="carbon:chevron-right" sx={{ ml: -0.5 }} width={16} />
                  </Hidden>
                }
                onClick={() => signup.onToggle('register', '/start')}
                sx={{ pl: { xs: 1.5, md: 2 } }}
              >
                {buttonLabel}
              </Button>
            )}
            {isDemoButtonVisible && (
              <Button
                size="small"
                onClick={() => signup.onToggle('contact')}
                sx={{ textTransform: 'none' }}
              >
                Request a demo
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
