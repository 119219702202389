'use client';

import PropTypes from 'prop-types';
import { Button, Link as MuiLink } from '@mui/material';
import { useSignupContext } from '../signup-popup/context/signup-context';

PrismicSignupButton.propTypes = {
  children: PropTypes.any,
  action: PropTypes.string,
  redirect: PropTypes.string,
  type: PropTypes.string,
  sx: PropTypes.object,
  colorVariant: PropTypes.string,
};

export default function PrismicSignupButton({
  children,
  action,
  redirect,
  colorVariant,
  type = 'button',
  sx,
  ...rest
}) {
  const signup = useSignupContext();

  const variantSx = colorVariant === 'dark' ? { color: 'common.white' } : {};

  if (type === 'text') {
    return (
      <MuiLink onClick={() => signup.onToggle(action, redirect)} rel="noopener" sx={{ ...sx, ...variantSx }} {...rest}>
        {children}
      </MuiLink>
    );
  }

  return (
    <Button
      onClick={() => signup.onToggle(action, redirect)}
      rel="noopener"
      sx={{ ...sx, ...variantSx }}
      {...rest}
    >
      {children}
    </Button>
  );
}
