'use client';

// icons
// @mui
import { Box, Button, Hidden, Typography } from '@mui/material';
// utils
// routes
// components
import { Image } from 'src/components/landing';
import { useSignupContext } from 'src/components/signup-popup/context/signup-context';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import LinkList from 'src/components/landing/LinkList';
import OnboardingWebsite from '../../onboarding/onboarding-website';
import { HOST_WEBSITE } from '../../../config-global';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const ONBOARDING_URL = `${HOST_WEBSITE}/start`;

export default function TechTitle({
  title,
  titleComponent = 'h1',
  description,
  icon,
  links,
  imageUrl,
  hideLinksOnMobile = false,
  hideButton = false,
  spacing = { xs: 3, md: 6 },
  redirect = null,
  showWebsiteGuessing = false,
}) {
  const signup = useSignupContext();

  const handleNext = () => {};

  const handleCmsGuessed = (guesses, url) => {
    const urlParams = new URLSearchParams({ url, guesses }).toString();

    window.location.href = `${redirect || ONBOARDING_URL}?${urlParams}`;
  };

  return (
    <Box>
      <Container>
        <Box sx={{ pt: { xs: 4, sm: 20 }, pb: { xs: 4, md: 10 } }}>
          <Grid container spacing={{ md: 2 }} justifyContent="center">
            <Grid xs={12} md={10}>
              <Stack spacing={spacing} sx={{ textAlign: 'center' }}>
                {links &&
                  (!hideLinksOnMobile ? (
                    <LinkList
                      sx={{ display: 'flex', justifyContent: 'center', mb: { sx: -1, md: 0 } }}
                      links={links}
                    />
                  ) : (
                    <Hidden smDown implementation="css">
                      <LinkList
                        sx={{ display: 'flex', justifyContent: 'center', mb: { sx: -1, md: 0 } }}
                        links={links}
                      />
                    </Hidden>
                  ))}
                <Typography component={titleComponent} variant="h2">
                  {title}
                </Typography>
                <Box>{description}</Box>
                {showWebsiteGuessing && (
                  <Stack sx={{ maxWidth: 700, mx: 'auto' }} spacing={1.5}>
                    <Typography variant="subtitle1" sx={{ fontSize: '1.3rem' }}>
                      Where do you want to install Tracklution?
                    </Typography>
                    <OnboardingWebsite onNext={handleNext} onCmsGuessesChanged={handleCmsGuessed} simple onboardingUrl={redirect} />
                  </Stack>
                )}
                {!hideButton && (
                  <Button
                    color="inherit"
                    size="large"
                    variant="contained"
                    onClick={() => signup.onToggle('register', redirect)}
                    endIcon={<Iconify icon="carbon:chevron-right" width={22} />}
                    rel="noopener"
                    sx={{ mx: 'auto', mt: 1 }}
                  >
                    Get Tracklution Today
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {imageUrl && (
          <Box>
            <Image alt={title} src={imageUrl} />
          </Box>
        )}
      </Container>
    </Box>
  );
}

TechTitle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.any,
  icon: PropTypes.string,
  links: PropTypes.array,
  spacing: PropTypes.object,
  hideButton: PropTypes.bool,
  titleComponent: PropTypes.string,
  hideLinksOnMobile: PropTypes.bool,
  imageUrl: PropTypes.string,
  redirect: PropTypes.string,
  showWebsiteGuessing: PropTypes.bool,
};
