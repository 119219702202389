'use client';

import Stack from '@mui/material/Stack';
import { Box, Container, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Iconify from 'src/components/iconify';
import { axiosPost } from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { fireCheckedUrl } from '../../utils/tracking';
import { useSignupContext } from '../../components/signup-popup/context/signup-context';
import { IS_CLIENT_SERVER } from '../../config-global';
import { useResponsive } from '../../hooks/use-responsive';
import { useSearchParams } from '../../routes/hooks';
// ----------------------------------------------------------------------

const guessingTexts = [
  'Checking the website...',
  'Perfoming magic...',
  'Analyzing the data...',
  'Crunching the numbers...',
];

// const DEFAULT_ONBOARDING_URL = `${HOST_WEBSITE}/start`;
const DEFAULT_ONBOARDING_URL = `/start`;

let quessingTextInterval = null;
let isWebsiteOnNextCalled = false;

export default function OnboardingWebsite({
  clients,
  onCmsGuessesChanged,
  onNext,
  sx,
  inputSx,
  simple = false,
  align = 'left',
  variant = null,
  onboardingUrl = null,
}) {
  const signup = useSignupContext();
  const { enqueueSnackbar } = useSnackbar();
  const mdUp = useResponsive('up', 'md');
  const searchParams = useSearchParams();

  const [isGuessing, setIsGuessing] = useState(false);
  const [guessingTextIndex, setGuessingTextIndex] = useState(0);

  const [websiteUrl, setWebsiteUrl] = useState('');
  const [selectedClientId, setSelectedClientId] = useState(null);

  useEffect(() => {
    const currentCheckUrl = searchParams.get('url');
    if (currentCheckUrl && !isWebsiteOnNextCalled) {
      isWebsiteOnNextCalled = true;
      onNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCmsGuessedRedirect = (guesses, url, clientId) => {
    const urlParams = new URLSearchParams({ url, guesses, client: clientId || '' }).toString();

    window.location.href = `${onboardingUrl || DEFAULT_ONBOARDING_URL}?${urlParams}`;
  };

  const onSubmitWebsiteUrl = async () => {
    const url = `/network/check-url`;
    setIsGuessing(true);

    try {
      const { success, guesses, checkUrl } = await axiosPost(url, {
        checkUrl: websiteUrl,
      });
      if (success) {
        if (onCmsGuessesChanged) {
          onCmsGuessesChanged(guesses, checkUrl, selectedClientId);
        }
        if (simple) {
          handleCmsGuessedRedirect(guesses, checkUrl, selectedClientId);
        }
        fireCheckedUrl();
        // enqueueSnackbar(message, { variant: 'success', autoHideDuration: 10000 });
      }
      setIsGuessing(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(typeof error === 'string' ? error : error.message, {
        variant: 'error',
        autoHideDuration: 10000,
      });
      setIsGuessing(false);

      onNext();
    }
  };

  useEffect(() => {
    if (!selectedClientId && clients?.length > 0) {
      setSelectedClientId(clients[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  useEffect(() => {
    if (!isGuessing) {
      clearInterval(quessingTextInterval);
    }
    quessingTextInterval = setInterval(() => {
      setGuessingTextIndex((prevState) => (prevState + 1) % guessingTexts.length);
    }, 3000);
    return () => clearInterval(quessingTextInterval);
  }, [isGuessing]);

  const useShortInput = !(mdUp || !clients?.length);

  const renderWebsiteStep = (
    <Stack spacing={3.5} sx={sx}>
      <Stack spacing={3}>
        {!simple && (
          <Stack spacing={1.5}>
            {false && <Typography variant="overline">Choose website</Typography>}
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '2.1rem!important', md: '2.5rem!important' },
                lineHeight: 1.2,
              }}
            >
              Where do you want to install Tracklution?
            </Typography>
            <Typography variant="body1">
              On average Tracklution users report +34.2 % improved conversions.
            </Typography>
          </Stack>
        )}
        <Stack spacing={0.75}>
          {!simple && (
            <Stack>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: simple ? '1.3rem' : '1.1rem', mb: simple ? 0.5 : 0 }}
              >
                Where do you want to install Tracklution?
              </Typography>
            </Stack>
          )}

          <TextField
            size="large"
            defaultValue={websiteUrl}
            onChange={(event) => {
              if (isGuessing) {
                return;
              }
              setWebsiteUrl(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.target.blur();
                onSubmitWebsiteUrl();
              }
            }}
            focused
            name="url"
            fullWidth
            placeholder="e.g. tracklution.com"
            InputProps={{
              startAdornment: (
                <Stack direction="row" alignItems="center">
                  {!useShortInput && (
                    <InputAdornment position="start" sx={{ mt: -0.25, ml: 0.5, mr: 1 }}>
                      <Iconify
                        icon="solar:global-outline"
                        sx={{ width: 22, height: 22, color: 'accent.main' }}
                      />
                    </InputAdornment>
                  )}
                  {clients?.length > 1 && (
                    <Select
                      name="client_id"
                      value={selectedClientId}
                      onChange={(event) => setSelectedClientId(event.target.value)}
                      sx={{
                        mx: useShortInput ? -0.5 : 0.5,
                        width: 'auto',
                        maxWidth: useShortInput ? 100 : 200,
                        bgcolor: (theme) => `${theme.palette.background.dark}!important`,
                        pl: 2,
                        pr: '0!important',
                        py: 0.25,
                        borderRadius: '8px!important',
                        '& .MuiSelect-select': {
                          minHeight: 0,
                          py: 0,
                          pl: 0,
                          pr: useShortInput ? '16px!important' : 4,
                        },
                        '& fieldset': {
                          border: 'none',
                        },
                      }}
                    >
                      {clients?.map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                          {client.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Stack>
              ),
              endAdornment: simple && (
                <Button
                  size="default"
                  variant="contained"
                  sx={{
                    px: useShortInput ? 1 : 3.5,
                    mr: -0.75,
                    ml: 0.75,
                    fontSize: '1.05rem',
                    minWidth: 30,
                  }}
                  color="accent"
                  disabled={isGuessing}
                  onClick={onSubmitWebsiteUrl}
                >
                  {!useShortInput ? (
                    'Start'
                  ) : (
                    <Iconify icon="solar:alt-arrow-right-bold" width={{ xs: 20, md: 48 }} />
                  )}
                </Button>
              ),
            }}
            sx={{
              pointerEvents: isGuessing ? 'none' : 'auto',
              minWidth: 150,
              '& .MuiInputBase-input': {
                typography: 'body1',
                pl: 1.25,
                borderTopLeftRadius: '0!important',
                borderBottomLeftRadius: '0!important',
              },
              '& .MuiInputBase-root': {
                bgcolor: 'background.paper',
              },
              ...inputSx,
            }}
          />
          {isGuessing && (
            <Stack>
              <LinearProgress color="accent" sx={{ width: 1, height: 10, mr: -1 }} />
              <Typography
                variant="body2"
                sx={{ mt: 0.125, mb: -1.25, color: variant === 'dark' ? '#ffffff' : 'inherit' }}
              >
                {guessingTexts[guessingTextIndex]}
              </Typography>
            </Stack>
          )}
          {!isGuessing && !simple && (
            <Typography
              variant="body2"
              sx={{
                mt: 0.125,
                textAlign: { xs: 'left', md: align === 'center' ? 'center' : 'left' },
              }}
            >
              {simple
                ? 'Enter a valid URL.'
                : "We'll use the URL to personalize your setup process."}
            </Typography>
          )}
        </Stack>
      </Stack>
      {!simple && (
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{ mt: 1.5, mb: 0.75 }}
        >
          {!IS_CLIENT_SERVER && signup.onboardingSource?.type !== 'agency' && (
            <Button
              size="large"
              onClick={() => signup.onToggle('contact')}
              sx={{ px: 2, ml: -2, py: 3.25, textAlign: 'left', textDecoration: 'underline' }}
            >
              Agency? Start here »
            </Button>
          )}
          <Box sx={{ flexGrow: 1 }} />

          <Button
            size="large"
            variant="contained"
            sx={{ px: 3.5, py: 3.25, fontSize: '1.05rem' }}
            color="accent"
            disabled={!websiteUrl || isGuessing}
            onClick={onSubmitWebsiteUrl}
          >
            Continue
          </Button>
        </Stack>
      )}
    </Stack>
  );

  if (simple) {
    return <>{renderWebsiteStep}</>;
  }

  return <Container maxWidth="md">{renderWebsiteStep}</Container>;
}

OnboardingWebsite.propTypes = {
  clients: PropTypes.array,
  onCmsGuessesChanged: PropTypes.func,
  onNext: PropTypes.func,
  sx: PropTypes.object,
  inputSx: PropTypes.object,
  simple: PropTypes.bool,
  align: PropTypes.string,
  variant: PropTypes.string,
  onboardingUrl: PropTypes.any,
};
