import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import { getContainerPx, getMaxWidth } from '../../theme/landing/spacing';
import cssStyles from '../../utils/cssStyles';

PrismicContainer.propTypes = {
  slice: PropTypes.any,
  children: PropTypes.node,
  width: PropTypes.string,
  variant: PropTypes.string,
  sliceSpacing: PropTypes.object,
  slicePadding: PropTypes.object,
  disablePaddingTop: PropTypes.bool,
  disablePaddingBottom: PropTypes.bool,
  isHero: PropTypes.bool,
  sx: PropTypes.object,
};

export default function PrismicContainer({
                                           slice,
                                           children,
                                           sliceSpacing,
                                           slicePadding,
                                           variant = 'default',
                                           width = 'md',
                                           disablePaddingTop = false,
                                           disablePaddingBottom = false,
                                           isHero = false,
                                           sx,
                                         }) {
  const getBackground = (currentVariant) => {
    if (currentVariant === 'light-gradient') {
      return { background: `linear-gradient(rgba(0,0,0,0) 50%, #f5f5f5 100%)` };
    }
    if (currentVariant === 'dark') {
      return cssStyles().bgImage({
        startColor: 'rgba(22, 28, 36, 0.8)',
        endColor: 'rgba(22, 28, 36, 0.8)',
      });
    }
    return { background: 'transparent' };
  };

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={variant === 'dark' || isHero ? 'dark-section' : 'light-section'}
    >
      <Box
        sx={{
          // overflow: 'hidden',
          ...getBackground(variant),
          py: {
            xs: slicePadding.xs * 3,
            md: slicePadding.md * 3,
          },
          ...(disablePaddingTop && { pt: { xs: 0, md: 0 } }),
          ...(disablePaddingBottom && { pb: { xs: 0, md: 0 } }),
          ...sx
        }}
      >
        <Container
          sx={{
            px: getContainerPx(width),
            maxWidth: `${getMaxWidth(width)}px!important`,
          }}
        >
          {children}
        </Container>
      </Box>
    </section>
  );
}
